import {customEvent} from "@songfinch/shared/plugins/sf_analytics";

export default class SongPlayerPlaybackReporter {
    constructor(player, progressReportIntervalSec = 30) {
        this.player = player;
        this.lastplaybackReportSentAt = 0;
        this.progressReportIntervalSec = progressReportIntervalSec;
        this.hasReportedStartEvent = false;
        this.hasReportedEndEvent = false;
    }

    onLoaded() {
        //If we are already supposed to be playing, and the song just loaded, report the start
        if (!this.hasReportedStartEvent && this.player.isPlaying) this._reportSongStartedEvent();
        return this;
    }

    onPlay() {
        //Only report status if the song is loaded
        if (!this.hasReportedStartEvent && this.player.songLoaded) this._reportSongStartedEvent();
    }

    /**
     * Called when a time update is received from HTML Media Element. If ended set as true
     * we will report progress to indicate 100% playback
     *
     * @param ended Boolean indicating if this was triggered due to file ending playback
     */
    onTimeUpdate(ended = false) {
        //Return early if we have received the end event or haven't already started tracking
        //progress. Flags will reset when we start a new song
        if (!this.hasReportedStartEvent || this.hasReportedEndEvent) return;
        this._reportSongListenedEventIfTimeElapsed(ended);
    }

    _reportSongStartedEvent() {
        //If this is us starting play tracking, set the seconds progress tracker to 0
        // We'll send progress updates at regular intervals according to progressReportIntervalSec
        this.lastplaybackReportSentAt = 0;
        //Flip tracking bool to indicate we don't need to send another start event
        customEvent("_sf_song_started", {
            song: this.player.getAudioFileMeta(),
            player: this.player.getPlaybackMeta()
        });
        this.hasReportedStartEvent = true;
        //Reset end tracking since we are starting tracking
        this.hasReportedEndEvent = false;
    }

    _reportSongListenedEventIfTimeElapsed(ended = false) {
        //If we have exceeded the last progressReportIntervalSec, report progress
        if (((this.lastplaybackReportSentAt + this.progressReportIntervalSec) <= this.player.currentRange) || ended) {
            //Store the playback time we are reporting at
            this.lastplaybackReportSentAt = this.player.currentRange;
            const payload = {
                song: this.player.getAudioFileMeta(),
                player: this.player.getPlaybackMeta()
            };
            //Set these values at max to avoid any integer / float math issues
            //This should result is us reporting 100% complete progress
            if (ended) {
                payload.player.currentRange = payload.player.maxRange;
                payload.player.currentTime = payload.player.duration;
            }
            //Only report progress if we haven't already reported an end event
            //This will always execute the RHS as long as we haven't reported an end event
            this.hasReportedEndEvent || customEvent("_sf_song_listened", payload);
            //Flip waiting for end event switch if this is an end event
            if (ended && !this.hasReportedEndEvent) {
                this.hasReportedEndEvent = true;
            }
        }
    }
}
