import "sweetalert2/dist/sweetalert2.min.css";
import "vue2-animate/dist/vue2-animate.min.css";
import {reactive, shallowRef} from "vue";
import Swal from "sweetalert2";


function swalInit(app, options = {}) {
    const swalLocalInstance = Swal.mixin(options);
    const swalFunction = function (...args) {
        return swalLocalInstance.fire.call(swalLocalInstance, ...args);
    };
    Object.assign(swalFunction, Swal);
    Object.keys(Swal)
        .filter(key => typeof Swal[key] === "function")
        .forEach(methodName => {
            swalFunction[methodName] = swalLocalInstance[methodName].bind(swalLocalInstance);
        });
    app.config.globalProperties.$swal = swalFunction;
    app.provide("$swal", swalFunction);
}

export default {
    install(app, options) {
        swalInit(app, options);

        app.config.globalProperties.$sfModal = reactive({
            data: {
                // 	component: Login, //required, Component or function with import for async load
                // 	props: {popup: 1}, //optional
                // 	events: {confirm: (data)=>{...do} }, //optional
                // 	swalSettings: {showConfirmButton: true} //optional
                //  slide: "direction", //optional, values: "top", "bottom, "center-start", "center-end" //optional
                //  responsive: {1199: {slide: "bottom"}}
                //  appClass: "" // for useAdditionalAppClass
            },
            async show(payload) {
                if (payload?.component) {
                    let component = payload?.component;
                    if (typeof payload?.component === "function") {
                        component = (await payload.component()).default;
                    }
                    payload.component = shallowRef(component);
                }
                setTimeout(() => this.data = payload);
            }
        });
    }
};
