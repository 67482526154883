import dayjs from "dayjs";

import {App, Plugin} from "vue";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $dayjs: dayjs;
        $defaultImage: "content-files/image_placeholder";
        $defaultImage2: "https://content.songfinch.com/res/songfinch/image/upload/content-files/image_placeholder/1651553598.png";
        $sfPriceInDollars: (
            price: number | string,
            details?: Intl.NumberFormatOptions
        ) => string;
        $cdnImage: {
            url(image: string, params?: Record<string, string>): string;
        };
    }
}

const sfHelper: Plugin = {
    install(app: App) {
        app.config.globalProperties.$dayjs = dayjs;
        app.config.globalProperties.$defaultImage =
            "content-files/image_placeholder";
        app.config.globalProperties.$defaultImage2 =
            "https://content.songfinch.com/res/songfinch/image/upload/content-files/image_placeholder/1651553598.png";
        app.config.globalProperties.$sfPriceInDollars = (
            price,
            options = {}
        ) => {
            try {
                return new Intl.NumberFormat("en-EN", {
                    style: "currency",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    currency: "USD",
                    ...options,
                }).format(price / 100);
            } catch (e) {
                return "Invalid format";
            }
        };
        app.config.globalProperties.$cdnImage = {
            url(image, params?) {
                if (!image) return "";
                params = {
                    // quality: "auto",
                    format: "auto",
                    ...params,
                };
                const url = new URL(image);
                url.search = new URLSearchParams(params).toString();
                return url.toString();
            },
        };

        app.provide("$cdnImage", app.config.globalProperties.$cdnImage);
    },
};

export default sfHelper;
