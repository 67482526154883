import {App, Plugin} from "vue";
import {cloneDeep} from "lodash-es";

function initSegment(swk) {
    const analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
        analytics.invoked = !0;
        analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
        analytics.factory = function (e) {
            return function (...arg) {
                arg.unshift(e);
                analytics.push(arg);
                return analytics;
            };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
            const key = analytics.methods[e];
            analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
            const t = document.createElement("script");
            t.type = "text/javascript";
            t.async = !0;
            t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
            const n = document.getElementsByTagName("script")[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = e;
        };
        analytics._writeKey = swk;
        analytics.SNIPPET_VERSION = "4.15.3";
        analytics.load(swk);

        setMessageIdOverwrite(analytics);
    }
}

function setMessageIdOverwrite(analytics) {
    const eventsToOverwrite = ["Song Purchased", "Order Completed", "Item Purchased"];

    analytics.addSourceMiddleware(({payload, next}) => {
        const event = payload.obj;
        if (eventsToOverwrite.includes(event.event) && event.context.event_id) {
            event.messageId = event.context.event_id;
        }
        next(payload);
    });
}

function initGTM(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        "gtm.start":
            new Date().getTime(), event: "gtm.js"
    });
    const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src =
        "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
}

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $customEvent: (event: string, details?: unknown) => void
    }
}

export const customEvent = (event, details?) => {
    if (!window.analytics && window.appSettings?.segmentWriteKey) initSegment(window.appSettings.segmentWriteKey);
    if (!window.dataLayer && window.appSettings?.gtmID) initGTM(window, document, "script", "dataLayer", window.appSettings.gtmID);

    document.dispatchEvent(new CustomEvent(event, {
        detail: cloneDeep(details)
    }));
};

const sfAnalytics: Plugin =  {
    install(app: App) {
        app.config.globalProperties.$customEvent = customEvent;
        app.provide("$customEvent", customEvent);

        app.config.globalProperties.$analyticPageViewedTrigger = () => {
            customEvent("_sf_pageview", {
                request_id: app.config.globalProperties.$store?.state.core_cms.__headers?.["x-request-id"],
            });
        };
    }
};


export default sfAnalytics;
